import { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Typography, useTheme, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCurrencyConvert, getTranslatedField } from '../hooks/useCurrency'
import { useTranslation } from 'react-i18next';

const Item = ({ item, width }) => {
  const currency = useSelector((state) => state.cart.currency);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const {
    palette: { neutral },
  } = useTheme();

  const { category, price, image } = item.attributes;
  const {
    data: {
      attributes: {
        formats: {
          medium: { url }
        },
      },
    },
  } = image;

  const itemName = getTranslatedField(item.attributes, "name", i18n.language);;

  return (
    <Box width={width}>
      <Box
        position="relative"
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
      >
        <img
          alt={item.name}
          width="300px"
          height="auto"
          src={`${process.env.REACT_APP_ETNOSOUL_BASE_URL}${url}`}
          onClick={() => navigate(`/item/${item.id}`)}
          style={{ cursor: "pointer" }}
        />
        <Box
          display={isHovered ? "block" : "none"}
          position="absolute"
          bottom="10%"
          left="0"
          width="100%"
          padding="0 5%"
        >
        </Box>
      </Box>

      <Box mt="3px">
        <Typography variant="subtitle2" color={neutral.dark}>
          {t(`generic.${category}`)
            .replace(/([A-Z])/g, " $1")
            .replace(/^./, (str) => str.toUpperCase())}
        </Typography>
        <Link underline="hover" href={`https://etnosoul.com.ua/item/${item.id}`}><Typography >{itemName}</Typography></Link>
        <Typography fontWeight="bold">{currency} {useCurrencyConvert(price, currency)}</Typography>
      </Box>
    </Box>
  );
};

export default Item;

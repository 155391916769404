import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function MetaBox() {
    const { t } = useTranslation();
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta name="description" content={t("meta.description")} />
                    <meta name="keywords" content={t("meta.keywords")} />
                    <meta name="author" content={t("meta.author")} />

                    <meta property="og:title" content={t("meta.title")} />
                    <meta property="og:description" content={t("meta.og.description")} />
                    <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
                    <meta property="og:url" content="https://etnosoul.com" />
                    <meta property="og:type" content="website" />

                    <meta name="twitter:card" content="%PUBLIC_URL%/logo512.png" />
                    <meta name="twitter:title" content={t("meta.title")} />
                    <meta name="twitter:description" content={t("meta.x.description")} />
                    <meta name="twitter:image" content="%PUBLIC_URL%/logo192.png" />
                </Helmet>
            </HelmetProvider>
        </>
    )
}
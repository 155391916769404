import { Box, Button, Stepper, Step, StepLabel } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as yup from "yup";
import { shades } from "../../theme";
import Payment from "./Payment";
import Shipping from "./Shipping";
import countryList from 'country-list';
import useScript from '../../hooks/useScript'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useConvert } from "../../hooks/useCurrency";
import Summary from "../checkout/Summary";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const Checkout = () => {
  const [activeStep, setActiveStep] = useState(0);
  const cart = useSelector((state) => state.cart.cart);
  const { Wayforpay } = useScript('https://secure.wayforpay.com/server/pay-widget.js', 'Wayforpay');
  const isFirstStep = activeStep === 0;
  const isSecondStep = activeStep === 1;
  const isThirdStep = activeStep === 2;
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currency = useSelector((state) => state.cart.currency);
  const { convertCurrency, getCurrencySymbol } = useConvert();
  const countries = countryList.getNames();
  const currencySymbol = getCurrencySymbol(currency);
  const deliveries = useSelector((state) => state.cart.deliveries);
  const isNonMobile = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    const onMessage = (event) => {
      if (event.data == "WfpWidgetEventApproved") {
        navigate(`/checkout/success`);
      }
      if (event.data == "WfpWidgetEventDeclined") {
        navigate(`/checkout/success`);
      }
    };

    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    }
  }, []);

  const handleFormSubmit = async (values, actions) => {
    setActiveStep(activeStep + 1);

    if (isFirstStep) {
    }

    if (isThirdStep) {
      makePayment(values);
    }

    actions.setTouched({});
  };

  async function makePayment(values) {

    const requestBody = {
      firstName: values.shippingAddress.firstName,
      lastName: values.shippingAddress.lastName,
      shippingAddress: values.shippingAddress,
      email: values.email,
      phone: values.phoneNumber,
      language: i18n.language,
      currency: currency,
      products: cart.map(({ id, count, size, variant, note }) => ({
        id,
        count,
        size,
        variant,
        note
      }))
    };
    const baseUrl = process.env.REACT_APP_ETNOSOUL_BASE_URL;
    const response = await fetch(`${baseUrl}/api/orders`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    });
    const data = await response.json();
    const invoiceData = data.invoiceData;

    const openPay = (Wayforpay) => {
      const wfp = new Wayforpay();
      invoiceData.language = i18n.language;
      wfp.invoice(data.url, true);
    }

    openPay(Wayforpay);

  }

  return (
    <Box width="80%" m="140px auto">
      <Stepper activeStep={activeStep} sx={{ m: "20px 0" }}>
        <Step>
          <StepLabel>{t('checkout.shipping')}</StepLabel>
        </Step>
        <Step>
          <StepLabel>{t('checkout.order-summary')}</StepLabel>
        </Step>
        <Step>
          <StepLabel>{t('checkout.payment')}</StepLabel>
        </Step>
      </Stepper>
      <Box>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema[activeStep]}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              {isFirstStep && (
                <Shipping
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  countries={countries}
                  convertCurrency={convertCurrency}
                  currencySymbol={currencySymbol}
                  currency={currency}
                  deliveries={deliveries}
                  isNonMobile={isNonMobile}
                />
              )}

              {isSecondStep && (
                <Payment
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              )}
              {isThirdStep && (
                <Summary
                  values={values}
                />
              )}
              <Box display="flex" justifyContent="space-between" gap="50px">
                {!isFirstStep && (
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    sx={{
                      backgroundColor: shades.primary[200],
                      boxShadow: "none",
                      color: "white",
                      borderRadius: 0,
                      padding: "15px 40px",
                    }}
                    onClick={() => setActiveStep(activeStep - 1)}
                  >
                    {t('checkout.back')}
                  </Button>
                )}
                <Button
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="contained"
                  sx={{
                    backgroundColor: shades.primary[400],
                    boxShadow: "none",
                    color: "white",
                    borderRadius: 0,
                    padding: "15px 40px",
                  }}
                >
                  {isThirdStep ? t('checkout.place-order') : t('checkout.next')}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box >
  );
};

const initialValues = {
  shippingAddress: {
    isSameAddress: true,
    firstName: "",
    lastName: "",
    country: "",
    street1: "",
    street2: "",
    city: "",
    state: "",
    zipCode: "",
    delivery: "",
  },
  email: "",
  phoneNumber: "",
};

const checkoutSchema = [
  yup.object().shape({
    shippingAddress: yup.object().shape({
      firstName: yup.string().required("required"),
      lastName: yup.string().required("required"),
      country: yup.string().required("required"),
      street1: yup.string().required("required"),
      street2: yup.string(),
      city: yup.string().required("required"),
      state: yup.string().required("required"),
      zipCode: yup.string().required("required"),
      delivery: yup.string().required("required")
    }),
  }),
  yup.object().shape({
    email: yup.string().required("required"),
    phoneNumber: yup.string().required("required"),
  }),
];

export default Checkout;

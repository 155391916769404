import { createTheme } from "@mui/material/styles";

export const shades = {
  primary: {
    500: "#0E2A22"
  },
  secondary: {
    500: "#F1633F"
  },
  neutral: {
    500: "#F8F8F8",
  },
  buttons: {
    100: "#F1633FFF"
  },
  alert: {
    500: "#F83F32"
  }
};

export const theme = createTheme({
  palette: {
    primary: {
      main: shades.primary[500]
    },
    secondary: {
      main: shades.secondary[500]
    },
    alert: {
      main: shades.alert[500]
    },
    neutral: {
      main: shades.neutral[500]
    },
    info: {
      main: '#F1633F'
    },
    infobar: {
      main: '#0E2A22'
    }
  },
  typography: {
    fontFamily: ["Cormorant Infant", "sans-serif"].join(","),
    fontSize: 11,
    h1: {
      fontFamily: ["Cormorant Infant", "sans-serif"].join(","),
      fontWeight: "700",
      color: 'var(--GREEN, #0E2A22)',
      fontSize: '2rem',
      fontStyle: 'normal',
      lineHeight: '2.5rem' /* 120% */,
      textTransform: 'uppercase'
    },
    h2: {
      fontFamily: ["Cormorant Infant", "sans-serif"].join(","),
      fontSize: 30,
    },
    h3: {
      fontFamily: ["Cormorant Infant", "sans-serif"].join(","),
      fontSize: 26,
    },
    h4: {
      fontFamily: ["Cormorant Infant", "sans-serif"].join(","),
      fontSize: 24,
    },
    h5: {
      fontFamily: ["Cormorant Infant", "sans-serif"].join(","),
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: 22
    },
    h6: {
      fontFamily: ["Cormorant Infant", "sans-serif"].join(","),
      fontStyle: "normal",
      fontSize: 20,
    },
    subtitle1: {
      fontFamily: ["Lato"].join(","),
      fontStyle: "normal",
      fontSize: 18
    },
    caption: {
      fontFamily: ["Lato"].join(","),
      fontStyle: "normal",
      fontSize: 16,
      color: "darkgray"
    },
    body1: {
      fontFamily: ["Lato"].join(","),
      fontStyle: "normal",
      fontSize: 14,
      lineHeight: '22px'
    },

    Lato14Regular: {
      fontFamily: ["Lato"].join(","),
      fontStyle: "normal",
      fontSize: 14
    },

    Lato12Regular: {
      fontFamily: ["Lato"].join(","),
      fontStyle: "normal",
      fontSize: 12
    },
    Lato30Regular: {
      fontFamily: ["Lato"].join(","),
      fontStyle: "normal",
      fontSize: 30
    }
  },
});
